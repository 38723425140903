import { useCallback, useEffect, useState } from 'react';
import './../styles/view_all_in_category.css'
import moment from 'moment';
import axios from 'axios';
import indicator_icon from "../images/dots-loading.gif";
import { env } from '../env';
 
function format_time(timeString) {
  var H = +timeString.substr(0, 2);
  var h = H % 12 || 12;
  var ampm = (H < 12 || H === 24) ? " AM" : " PM";
  timeString = h + timeString.substr(2, 3) + ampm;
  return timeString;
}

var isScrolling;

var isInViewport = function (elem) {
  var bounding = elem.getBoundingClientRect();
  return (
      bounding.top >= 0 &&
      bounding.left >= 0 &&
      bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};
 
function AllVideos(props) {
  const [dataset, setDataset] = useState()
  const [loadingData, setLoadingData] = useState(true)
  const [page, setPage] = useState(1)
  const [videosList, setVideosList] = useState([])
  const [basePath, setBasePath] = useState('')
  // GEG specific layout settings
  let theme = "";
  let type =  "";
  let items_per_row =  3;

  useEffect(() => {
    // FETCH All Videos FOR Playlist
    setLoadingData(true)
    props.dataSet.playlist = props.dataSet.playlistID
    props.dataSet.page = page
    if(props.dataSet.playlistID)
    {
        axios.get( `${env.REACT_APP_API_URL}/video-assets/playlist`, {
        
          params: props.dataSet,
  
      }).then( ( response ) => {
          const bring_more_records = document.querySelector('#bring-more-records');
          if(response.status == 200)
          {

            /* Check if colors should be inherited from wp-options, embed code or db */
            if (document.sgl_plugin_page == '1' || document.sgl_plugin_page == 1) { 
              // background color
              if (!props.dataset?.background_color && response.data.dataset?.background_color == '#ffffff') {
                if (document?.sgl_plugin_colors?.primary_header_color_bg) {
                  response.data.dataset.background_color = document?.sgl_plugin_colors?.primary_header_color_bg
                }
              }

              //foreground color
              if (!props.dataset?.foreground_color && response.data.dataset?.foreground_color == '#000000') {
                if (document?.sgl_plugin_colors?.primary_header_color_txt) {
                  response.data.dataset.foreground_color = document?.sgl_plugin_colors?.primary_header_color_txt
                }
              }
            }

          setDataset(dataset => response.data.dataset)
          if(response.data.destination_basepath?.length > 0){
            setBasePath(basePath => response.data.destination_basepath)
          }
              if(response.data?.list?.length > 0)
              {
                  response.data.list.map((video_asset) => {
                      videosList.push(video_asset)
                  })
                  setLoadingData(false)
                  bring_more_records.style.visibility = "visible"
              }else{
                setLoadingData(true)
                bring_more_records.style.visibility = "hidden"
              }
              
          }
      } );  
    }
 },[page])

 window.addEventListener('scroll', function ( event ) {

    window.clearTimeout( isScrolling );
    isScrolling = setTimeout(function() {
      if(!loadingData){
        const bring_more_records = document.querySelector('#bring-more-records');
        if(isInViewport(bring_more_records)){
          setPage(page => page+1)
        }
      }
    }, 66);

}, false);

if(typeof(dataset) !== 'undefined')
  {
    theme = (typeof (dataset.theme) != 'undefined' && dataset.theme != '') ? dataset.theme?.toLowerCase() : "";
    type = (typeof (dataset.type) != 'undefined' && dataset.type != '') ? dataset.type : "";
    items_per_row = (typeof (dataset.items_per_row) != 'undefined' && dataset.items_per_row > 0) ? dataset.items_per_row : 3;

  }

  
  return (
    <>
      <style>
        {
          items_per_row && (
            ` .sgl-view-all .card-deck {\
                grid-template-columns: repeat(`+ items_per_row + `, 1fr);\
              }`
          )
        }
        {
          (dataset?.background_color && (document.sgl_plugin_page == '1' || document.sgl_plugin_page == 1)) && (
            `.sgl-video-section .sgl-view-all .sgl-title-section {\
              background-color: ${dataset?.background_color} !important;\
            }\
            .sgl-video-section .sgl-view-all .card-deck .sgl-item.sgl-post.sgl-standard-post .sgl-post-content h2 a {\
              color: ${dataset?.background_color};\
            }`
          )
        }
      </style>
      <section className={"sgl-view-all " + theme}>
        <div className="sgl-title-section">
          <h1><span>{dataset?.display_title}</span></h1>
          {dataset?.category?.length > 0 && <h2><span>{dataset.category}</span></h2>}
        </div>
        <div className="card-deck">
          {
            videosList.map((video_asset, index)=> {
              return (
                  <div key={video_asset.sgl_id} className="sgl-item sgl-post sgl-standard-post"
                    onClick={(event) => {
                      if(video_asset.type == 'rvs')
                      {
                        window.location.href = basePath+`/video/rider-clip/${video_asset.sgl_id}/${video_asset.slug}`
                      } else if(document.sgl_plugin_page == '1' || document.sgl_plugin_page == 1){
                        window.location.href = basePath+`/video-detail/${video_asset.sgl_id}/${video_asset.slug}`
                      } else{
                        window.location.href = basePath+`/video/${video_asset.sgl_id}/${video_asset.slug}`
                      }
                      
                    }}
                  >
                     <div>
                    <div className="sgl-post-gallery">




                    <img src={video_asset.placeholder_image != '' ? video_asset.placeholder_image : "https://showgroundslive.com/images/placeholder_video_small.jpg"} style={{maxWidth:"100%"}} onError={(e)=>{ e.target.src = "https://showgroundslive.com/images/placeholder_video_small.jpg"}}/>
                          {
                            (video_asset.event_started=='0' && video_asset.type !=="Uploaded Videos" && video_asset.type !=="rvs")?
                            <div className='sgl-video-state'>UPCOMING</div>
                            :
                            ""
                          }
                          {
                            (video_asset.event_started=='1' && video_asset.event_ended != '1' && video_asset.type !=="Uploaded Videos" && video_asset.type !=="rvs")?
                            <div className='sgl-video-state'>LIVE NOW</div>
                            :
                            ""
                          }
                      <a className="sgl-play" href="javascript:void(0);">
                        <div className="sgl-play-icon" ><span >&#x25BA;</span>
                        </div>
                      </a>
                     
                      </div>
                      
                    </div>
                    <div className="sgl-post-content">
                      <h2>
                        <a href="javascript:void(0);">{video_asset.title}</a>
                      </h2>
                      {
                          (video_asset.type == "Live Stream") &&
                          <>
                            <p className="sgl-carousel-text">{
                            moment(`${video_asset.event_date.split('T')[0]} ${video_asset.event_time}`).format('dddd, MMMM D YYYY hh:mm a')
                            }</p>
                          </>
                        }
                        {
                          (video_asset.type == "Ring Stream Configuration") &&
                          <>
                          {
                          (video_asset.header_classes?.length > 0) &&
                          video_asset.header_classes.slice(0, 1).map((data, index) => {
                            return (
                              (data.interval == "Live Now:") ?
                                <p class="text"><span class="green sgl-carousel-label">{data.interval} </span>{data.header} {(data.est_time && data.time !== "00:00:00")?<b>Est. Start: </b>:''}{(data.est_time && data.time !== '00:00:00')?format_time(data.time):''}</p>
                              :
                                <p class="text"><span class="orange sgl-carousel-label">{data.interval} </span>{data.header} {(data.est_time && data.time !== "00:00:00")?<b>Est. Start: </b>:''}{(data.est_time && data.time !== '00:00:00')?format_time(data.time):''}</p>
                            )
                          })
                        }
                        {
                          (video_asset.show_header_message) &&
                            <p class="text">No classes scheduled in this ring.</p>
                        }
                          </>
                        }
                        {
                          (video_asset.type != "Ring Stream Configuration" && video_asset.description!='') &&
                          <p class="text">{video_asset.description}</p>
                        }
                      
                    </div>
                  </div>
                
              )
            })
          }
        </div>
        <div id="bring-more-records" style={{textAlign:"center"}}><img src={indicator_icon} /> </div>
      </section>
    </>
  );


}
 
export default AllVideos;
 

